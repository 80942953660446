import React, { useState, useEffect, useRef } from "react"
import useOutsideClick from "../useOutsideClick"

import { useGlobalContext } from "../../context/layout-context"

import DesktopNavigation from "../navigation/desktop-navigation"
import MobileNavigation from "../navigation/mobile-navigation"

function Header({ main, headerButtons, pageProps }) {
  const { globalState, updateGlobalState } = useGlobalContext()
  const { isDesktop, isModalOpen } = globalState || {}
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false)
  const hamburgerRef = useRef()
  const drawerRef = useRef()
  const headerRef = useRef()

  // Get Header Heigh on resize
  const getHeaderHeight = () => {
    setTimeout(() => {
      updateGlobalState({
        headerHeight: headerRef.current.clientHeight,
      })
    }, 300)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  // Hamburger Functions for Tablet and Mobile
  const openHamburger = () => {
    // CHECK SIZE FOR WINDOW
    setIsHamburgerOpen(!isHamburgerOpen)
  }

  useOutsideClick(drawerRef, () => {
    if (isHamburgerOpen) setIsHamburgerOpen(false)
  })

  useEffect(() => {
    const html = document.getElementsByTagName("html")

    if (isHamburgerOpen) {
      html[0].classList.add("overflow-hidden")
    } else {
      html[0].removeAttribute("class")
    }

    const resizeWindow = () => {
      setTimeout(() => {
        getHeaderHeight()
      }, 120)
    }
    setTimeout(() => {
      getHeaderHeight()
    }, 120)
    window.addEventListener("resize", resizeWindow)

    return () => {
      updateGlobalState({
        headerHeight: null,
      })
      window.removeEventListener("resize", resizeWindow)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHamburgerOpen, updateGlobalState])

  return (
    <header
      className={`z-50 inset-x-0 top-0 py-4 lg:py-0 bg-white shadow-sm sticky duration-150 transition-all transform ${
        isModalOpen ? "-translate-y-full" : "translate-y-0"
      }`}
      ref={headerRef}
      data-layer-region="header"
    >
      <div className="container relative">
        <div className="flex items-center flex-wrap -ml-8">
          {isDesktop ? (
            <DesktopNavigation
              main={main}
              headerButtons={headerButtons}
              isHamburgerOpen={isHamburgerOpen}
              openHamburger={openHamburger}
              drawerRef={drawerRef}
              setIsHamburgerOpen={setIsHamburgerOpen}
              hamburgerRef={hamburgerRef}
              pageProps={pageProps}
            />
          ) : (
            <MobileNavigation
              main={main}
              headerButtons={headerButtons}
              isHamburgerOpen={isHamburgerOpen}
              openHamburger={openHamburger}
              drawerRef={drawerRef}
              setIsHamburgerOpen={setIsHamburgerOpen}
              hamburgerRef={hamburgerRef}
              isDesktop={isDesktop}
              pageProps={pageProps}
            />
          )}
        </div>
      </div>
    </header>
  )
}

export default Header
